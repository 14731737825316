// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item ion-label {
  font-size: 14px;
  color: #061237;
  font-weight: 500;
}

ion-item ion-icon {
  font-size: 18px;
  color: #00d5b4;
  margin-inline-end: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.logo img {
  width: 190px;
}

ion-item {
  --min-height: 40px;
  margin: 0 10px;
}

ion-menu ion-item:hover {
  --background: #00d5b4;
  border-radius: 10px;
}

ion-menu ion-item:hover ion-icon {
  color: #fff;
}

ion-menu ion-item:hover ion-label {
  color: #fff;
}

ion-accordion ion-list ion-item {
  border-bottom: none !important;
}

.ion-accordion-toggle-icon {
  font-size: 16px !important;
}

ion-button ion-label {
  font-size: 20px;
}

ion-button ion-icon {
  color: #a2a0ab;
  background: #f8f8f8;
  padding: 9px;
  font-size: 13px;
  border-radius: 50%;
}

.active {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AADJ;;AAIA;EACI,eAAA;EACA,cAAA;EAEA,uBAAA;EACA,eAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,YAAA;AAFJ;;AAKA;EACI,kBAAA;EAEA,cAAA;AAHJ;;AAMA;EACI,qBAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,WAAA;AAHJ;;AAMA;EACI,WAAA;AAHJ;;AAMA;EACI,8BAAA;AAHJ;;AAMA;EACI,0BAAA;AAHJ;;AAMA;EACI,eAAA;AAHJ;;AAMA;EACI,cAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AAHJ;;AAOA;EACI,UAAA;AAJJ","sourcesContent":["\r\n\r\nion-item ion-label {\r\n    font-size: 14px;\r\n    color: #061237;\r\n    font-weight: 500;\r\n}\r\n\r\nion-item ion-icon {\r\n    font-size: 18px;\r\n    color: #00d5b4;\r\n    //background-image: linear-gradient(160deg, var(--gradient-1), var(--gradient-1), var(--gradient-2), var(--gradient-3));\r\n    margin-inline-end: 10px;\r\n    margin-top: 6px;\r\n    margin-bottom: 6px;\r\n}\r\n\r\n.logo img {\r\n    width: 190px;\r\n}\r\n\r\nion-item {\r\n    --min-height: 40px;\r\n    //border-bottom: solid 1px #e6e6e6 !important;\r\n    margin: 0 10px;\r\n}\r\n\r\nion-menu ion-item:hover {\r\n    --background: #00d5b4;\r\n    border-radius: 10px;\r\n}\r\n\r\nion-menu ion-item:hover ion-icon {\r\n    color: #fff;\r\n}\r\n\r\nion-menu ion-item:hover ion-label {\r\n    color: #fff;\r\n}\r\n\r\nion-accordion ion-list ion-item {\r\n    border-bottom: none !important;\r\n}\r\n\r\n.ion-accordion-toggle-icon {\r\n    font-size: 16px !important;\r\n}\r\n\r\nion-button ion-label {\r\n    font-size: 20px;\r\n}\r\n\r\nion-button ion-icon {\r\n    color: #a2a0ab;\r\n    background: #f8f8f8;\r\n    padding: 9px;\r\n    font-size: 13px;\r\n    border-radius: 50%;\r\n}\r\n\r\n\r\n.active{\r\n    color: red;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
