// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl:'http://45.249.108.42:89/api/',
  apiUrl:'https://api.obicas.in/api/',
  fbUrl:'https://graph.facebook.com/v20.0/',
  //accessToken:'EAAEzLOeuidsBO3MG6ta9y1ux2MVNOLUhH2JX9t6Dqn68i4qsWXYvtUZCkNY3t7iZAl7rZCkS7F1FW2qNU2bMHkSCjbZBy8H5jgDV99xrSm0wSlXQ6GmZAyV44EOlUi2erkUOZCipoZBa4h8ItcQsUvs7yHjYPUbBZAfFKWAXIBT3ZA3lnz5DfEZAhnfSjUSneZC5ZANr1QZDZD' // for obicas
  accessToken:'EAANinZAZAqlW8BO7MH9vZBNU95qxkxytQDLKxXyKPrmJRoJRB9vqrQk1Ba5eaKjTJ9OChdtK7oMDicL14Izt9hWnk8ZAyfdZAbLxDFibZCAXiG3uYn3WuIWf07ZAN4FqJg91FzMFg0i0sQBNiRA5dhmBS0QjoGeQyI9zXc0mZAKF8Qtq4ZBZANjiODve0uceiFt5FQRQZDZD' // for innovartic
};

//export const baseUrl = 'https://apis.rmlconnect.net/'


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
