import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProfileComponent } from './profile/profile.component'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Location, LocationStrategy, HashLocationStrategy} from '@angular/common';
//import { UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DatePipe } from '@angular/common'
import { IonicNativePlugin } from '@ionic-native/core';
import { NgApexchartsModule } from 'ng-apexcharts';
//import { IonSelect, IonSelectOption, IonToast,IonText,IonLoading   } from '@ionic/angular';

@NgModule({
  declarations: [AppComponent,ProfileComponent],
  //entryComponents: [],
  imports: [NgApexchartsModule, PickerModule,NgxPaginationModule,NgSelectModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,FormsModule
    //,IonSelect, IonSelectOption, IonToast,IonText ,IonLoading ,
  ] ,
  
  providers: [DatePipe,IonicNativePlugin,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    //{ provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
